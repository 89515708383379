import axios from "axios";

// Live
const endpoint = "https://api.achieva.cloud";
// Testing
// const endpoint = "http://apiachieva.frp.t05.sg/";
// const endpoint = "https://api.swms.t05.sg";
// Local
// const endpoint = "http://localhost:3008";

const instance = axios.create({ baseURL: endpoint });

const API = (method, url, data, options) => {
  return instance({ method, url, data, withCredentials: true, ...options });
};

export default API;
