import pages from "../pages/index";

const routes = [
  // Warehouse
  {
    resource: "warehouse",
    path: "warehouse",
    element: pages.Warehouse,
    children: [
      { path: "new", element: pages.CreateNewProduct },
      { path: "stock-in", element: pages.StockIn },
      { path: ":id", element: pages.WHProductDetails },
    ],
  },
  {
    resource: "warehouse",
    path: "stock",
    element: pages.Stock,
    children: [
      { path: "stockTake", element: pages.StockTakeList },
      { path: "stockTake/:id", element: pages.StockTake },
    ],
  },
  {
    resource: "warehouse",
    path: "adjustment",
    element: pages.StockAdjustment,
    children: [
      { path: "new", element: pages.ManageStockAdjustment },
      { path: "edit/:id", element: pages.ManageStockAdjustment },
      { path: "view/:id", element: pages.ViewStockAdjustment },
    ],
  },
  {
    resource: "warehouse",
    path: "resupply",
    element: pages.Resupply,
  },
  {
    resource: "warehouse",
    path: "supplier",
    element: pages.Suppliers,
    children: [
      { path: "new", element: pages.CreateSupplier },
      { path: "taxRates", element: pages.TaxRates },
      { path: ":id", element: pages.SupplierDetails },
    ],
  },
  {
    resource: "warehouse",
    path: "orders",
    element: pages.PurchaseOrder,
    children: [
      { path: "new", element: pages.ManagePurchaseOrder },
      { path: "edit/:id", element: pages.ManagePurchaseOrder },
    ],
  },
  {
    resource: "warehouse",
    path: "goods",
    element: pages.GoodsReceived,
    children: [
      // { path: "new", element: pages.ManagePurchaseOrder },
      { path: "edit/:id", element: pages.ManageGoodsReceived },
    ],
  },
  // Operations
  {
    resource: "operations",
    path: "customer",
    element: pages.Customer,
    children: [
      { path: "new", element: pages.CreateNewCustomer },
      { path: ":id", element: pages.CustomerDetails },
    ],
  },
  {
    resource: "operations",
    path: "vending",
    element: pages.VendingMachines,
    children: [
      { path: "new", element: pages.CreateNewVM },
      { path: "iot-devices", element: pages.ViewIotDevices },
      { path: ":id", element: pages.VMDetails },
      { path: ":id/manage", element: pages.ManageInventoryV2 },
      // { path: ":id/inventory", element: pages.ManageInventoryV2 },
    ],
  },
  {
    resource: "operations",
    path: "route",
    element: pages.Route,
    children: [
      { path: "route", element: pages.Route },
      { path: "create", element: pages.CreateRoute },
      { path: "edit/:id", element: pages.EditRoute },
      { path: "view/:id", element: pages.ViewMachine },
      { path: "inventory/:subwarehouseId", element: pages.SubWarehouseStock },
      {
        path: "inventory/:subwarehouseId/stockTake",
        element: pages.SubWarehouseStockTakeList,
      },
      {
        path: "inventory/:subwarehouseId/stockTake/:id",
        element: pages.SubWarehouseStockTake,
      },
      { path: ":id/inventory", element: pages.SubRouteInventory },
    ],
  },
  {
    resource: "operations",
    path: "serviceman",
    element: pages.SubWarehouse,
    children: [
      { path: "new", element: pages.CreateServiceman },
      { path: ":id", element: pages.ServicemanDetails },
      { path: ":id/password", element: pages.SubChangePassword },
    ],
  },

  {
    resource: "operations",
    path: "jobs",
    element: pages.Jobs,
    children: [{ path: ":id", element: pages.JobsDetails }],
  },
  // Sales
  {
    resource: "sales",
    path: "sales",
    element: pages.Transactions,
    children: [{ path: "import", element: pages.Transactions_Import }],
  },
  {
    resource: "sales",
    path: "cash",
    element: pages.Cash,
  },

  // Reports
  {
    resource: "reports",
    path: "reports",
    element: pages.Reports,
  },

  // User
  {
    resource: "user",
    path: "users",
    element: pages.Account,
    children: [
      { path: "accounts", element: pages.Account },
      { path: "accounts/create", element: pages.CreateAccount },
      { path: "accounts/edit/:id", element: pages.EditAccount },
      { path: "accounts/password/:id", element: pages.ChangePassword },
      { path: "roles", element: pages.Role },
      { path: "roles/create", element: pages.CreateRole },
      { path: "roles/manage/:id", element: pages.ManageRole },
    ],
  },
];

export default routes;
